import React, { useState, useEffect, useCallback, useRef } from "react";
import moment from "moment";
import Markdown from "markdown-to-jsx";
import readingTime from "reading-time";
import { gql, ApolloClient, InMemoryCache } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";
import { GithubSelector, GithubCounter } from "react-reactions"; // Add these imports

import { config } from "../config";
import { getEmojiByName, getNameByEmoji } from "../Utils/emoji";
import { getAuthenticatedUser } from "../Utils/auth";
import { Loader } from "../Components/Common";
import {
  PostContainer,
  PostTitle,
  PostDate,
  PostDateLink,
  PostReaction,
  BackButton,
} from "../Components/Post";
import {
  AuthorDetails,
  AuthorAvatar,
  AuthorName,
} from "../Components/Post/Author";
import { GithubLogin } from "../Components/Header";
import { HyperLink, CodeBlock } from "../Components/Markdown/Overrides";
import CommentsSection from "./CommentsSection";
import { colorBrewer } from "react-syntax-highlighter/dist/esm/styles/hljs";

const client = new ApolloClient({
  uri: "https://api.github.com/graphql",
  headers: {
    Authorization: `Bearer ${config.githubConvertedToken}`,
  },
  cache: new InMemoryCache(),
});

const BlogHome = () => {
  const issueNumber = parseInt(window.location.href.split("/").pop());
  const GET_POSTS = gql`
    {
      repository(owner: "${config.githubUserName}", name: "${config.githubRepo}") {

      issue(number: ${issueNumber}) {
          title
          body
          bodyHTML
          url
          bodyText
          number
          bodyHTML
          author {
            url
            avatarUrl
            login
          }
          reactions(first: 100) {
            nodes {
              content
              user {
                id
                login
              }
            }
          }
          updatedAt
          id
          comments(first: 100) {
            nodes {
              author {
                ... on User {
                  avatarUrl
                  name
                  login
                }
              }
              body
              bodyHTML
              bodyText
              publishedAt
              updatedAt
            }
          }
        }
      }
    }
  `;

  const [post, setPost] = useState(null);
  const [postNodeId, setPostNodeId] = useState("");
  const [reactionPopup, setReactionPopup] = useState(false);
  const [postReactions, setPostReactions] = useState([]);
  const [postComments, setPostComments] = useState([]);
  const { loading, error, data } = useQuery(GET_POSTS, {
    client: client,
  });
  const reactionsContainer = useRef(null);
  const userToken = localStorage.getItem("githubToken");

  const setReactionFun = useCallback((reactions) => {
    let reactions_array = [];
    reactions.forEach((element) => {
      let obj = {
        by: element.user.login,
        emoji: getEmojiByName(element.content),
      };
      reactions_array.push(obj);
    });
    setPostReactions(reactions_array);
  }, []);

  const toggleReaction = async (emoji) => {
    let reactions = postReactions;
    const user = await getAuthenticatedUser();
    const existingReaction = reactions.filter(
      (r) => r.emoji === emoji && r.by === user.login
    );

    if (existingReaction.length === 0) {
      const reactionToAdd = {
        by: user.login,
        emoji: emoji,
      };

      await client.mutate({
        mutation: gql`
          mutation AddReaction {
            addReaction(input: { subjectId: "${postNodeId}", content: ${getNameByEmoji(
          emoji
        )}, clientMutationId: "${user.node_id}" }) {
              reaction {
                id
              }
            }
          }
        `,
      });

      reactions.push(reactionToAdd);
    } else {
      await client.mutate({
        mutation: gql`
          mutation RemoveReaction {
            removeReaction(input: { subjectId: "${postNodeId}", content: ${getNameByEmoji(
          emoji
        )}, clientMutationId: "${user.node_id}" }) {
              reaction {
                id
              }
            }
          }
        `,
      });

      reactions = reactions.filter(
        (r) => !(r.by === user.login && r.emoji === emoji)
      );
    }

    setPostReactions(reactions);
    reactionsContainer.current.forceUpdate();
    setReactionPopup(false);
  };

  useEffect(() => {
    if (!loading) {
      if (data) {
        const issue = data.repository.issue;
        setPostNodeId(issue.id);
        setPost(issue);
        setReactionFun(issue.reactions.nodes);
        setPostComments(issue.comments.nodes);
      }
    }
  }, [loading, error, data, setReactionFun]);

  if (loading) {
    return <Loader />;
  }

  const onBackClick = () => {
    window.history.go();
    window.history.back();
  };

  return (
    <>
      {post && (
        <PostContainer>
          <BackButton onClick={() => onBackClick()}>Back</BackButton>
          <PostTitle>{post.title}</PostTitle>
          <div>
            <AuthorDetails>
              <AuthorAvatar
                src={post.author.avatarUrl}
                alt={post.author.login}
              />
              <div>
                {/* <AuthorName>{post.author.login}</AuthorName> */}
                <AuthorName>
                  <a
                    href={`https://github.com/${post.author.login}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    {post.author.login}
                  </a>
                </AuthorName>
                <PostDate>
                  {moment(post.updatedAt).format("DD MMM YYYY")} .
                  {readingTime(post.body).minutes} Min Read .
                  <PostDateLink href={post.url} target="_blank">
                    View On Github
                  </PostDateLink>
                </PostDate>
              </div>
            </AuthorDetails>
          </div>
          <Markdown
            options={{
              overrides: {
                a: {
                  component: HyperLink,
                },
                pre: {
                  component: CodeBlock,
                },
              },
            }}
          >
            {post.body}
          </Markdown>
          {reactionPopup && (
            <PostReaction>
              {userToken ? (
                <GithubSelector onSelect={(emoji) => toggleReaction(emoji)} />
              ) : (
                <GithubLogin isAbsolute={false} />
              )}
            </PostReaction>
          )}
          <GithubCounter
            ref={reactionsContainer}
            counters={postReactions}
            onSelect={(emoji) => toggleReaction(emoji)}
            onAdd={() => setReactionPopup(!reactionPopup)}
          />
          <CommentsSection postUrl={post.url} comments={postComments} />
        </PostContainer>
      )}
    </>
  );
};

export default BlogHome;
